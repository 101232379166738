<!--suppress HtmlFormInputWithoutLabel -->
<div class="new-design">
    <div class="page-title">
        <header>Log In</header>
        <p>New User? <a tabindex="3" [routerLink]="['account-request']">Sign Up</a></p>
        <br />
        <p>
            If your needs only extend to historical data usage, a catalog of outage data from 2014 to present can be found
            <a target="_blank" href="https://figshare.com/s/417a4f147cf1357a5391">here</a> and all related file metadata and descriptions
            can be found in the related
            <a
                target="_blank"
                href="https://link.springer.com/article/10.1038/s41597-024-03095-5?utm_source=rct_congratemailt&utm_medium=email&utm_campaign=oa_20240305&utm_content=10.1038/s41597-024-03095-5">
                article</a
            >
            (<i>A dataset of recorded electricity outages by United States county 2014–2022</i>)
        </p>
    </div>

    <div class="login-form">
        <form [formGroup]="formGroup">
            <mat-form-field appearance="outline" subscriptSizing="fixed">
                <mat-label>Username or Email</mat-label>
                <input type="text" name="username" matInput autofocus formControlName="username" (keyup.enter)="login()" />
                <mat-error *ngIf="formGroup.controls['username'].errors">
                    <span class="invalid">Required</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" subscriptSizing="fixed">
                <mat-label>Password</mat-label>
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    name="password"
                    matInput
                    formControlName="password"
                    (keyup.enter)="login()" />
                <mat-icon matSuffix (click)="showPassword = !showPassword">
                    {{ showPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error *ngIf="formGroup.controls['password'].errors">
                    <span class="invalid">Required</span>
                </mat-error>
            </mat-form-field>
        </form>

        <div class="response-message">
            <div *ngIf="errorCode === 423" class="failure-text">
                Account Inactive -- Your account request is currently being reviewed contact
                <a href="mailto:eagle-i@ornl.gov">eagle-i&#64;ornl.gov</a> for questions.
            </div>

            <div *ngIf="errorCode === 403" class="failure-text">
                <fa-icon icon="exclamation" class="failure-text"></fa-icon> Your account has been disabled.
                <a (click)="goToReactivationPage()">Request account reactivation</a>.
            </div>

            <div *ngIf="errorCode === 401">
                <p class="failure-text">Your {{ emailOrUsername() }} or password is incorrect.</p>
            </div>

            <div *ngIf="errorCode === 400">
                <p class="failure-text">
                    Please click on the link that has been sent to your email account to verify your email and wait for an account approval
                    email before attempting to access your account. Contact <a href="mailto:eagle-i@ornl.gov">eagle-i&#64;ornl.gov</a> for
                    questions
                </p>
            </div>
        </div>

        <div class="reset-password">
            <a tabindex="4" [routerLink]="['reset-password']">Reset Password</a>
        </div>
        <p class="term-of-use" tabindex="5">By logging in, you agree to our <a target="_blank" routerLink="/termsOfUse">Terms of Use</a></p>
    </div>

    <div class="actions">
        <a mat-flat-button [href]="redirectUrl" color="primary" class="wide">Login with OneID <mat-icon>public</mat-icon></a>
        <button mat-flat-button color="primary" class="wide" tabindex="2" (click)="login()" [disabled]="formGroup.invalid">Log In</button>
        <a class="email-issue" tabindex="6" href="mailto:eagle-i@ornl.gov">Trouble Logging In?</a>
    </div>
</div>
